<template>
	<div class="animated fadeIn">
		<b-row class="mb-3">
			<b-col :cols="['xs', 'sm', 'md'].includes(windowWidth) ? 12 : 3">
				<label
					class="mb-2"
					for="warehouseId">
					{{ translate('warehouse') }}
				</label>
				<b-select
					id="warehouseId"
					v-model="warehouseId">
					<b-select-option :value="null">
						{{ translate('select_warehouse') }}
					</b-select-option>
					<b-select-option
						v-for="warehouse in warehouses"
						:key="warehouse.value"
						:value="warehouse.value">
						{{ warehouse.text }}
					</b-select-option>
				</b-select>
			</b-col>
			<b-col
				:cols="['xs', 'sm', 'md'].includes(windowWidth) ? 12 : 9"
				:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'mt-3' : ''"
				class="d-flex justify-content-end align-items-end">
				<b-row style="row-gap: 5px">
					<b-col
						:cols="['xs', 'sm'].includes(windowWidth) ? '12' : 'auto'"
						class="m-0 pl-0">
						<b-button
							v-b-modal="'importModal'"
							:disabled="warehouseId === null"
							class="ml-2 w-100"
							variant="primary">
							<i class="fas fa-file-csv" /> {{ translate('import') }}
						</b-button>
					</b-col>
					<b-col
						:cols="['xs', 'sm'].includes(windowWidth) ? '12' : 'auto'"
						class="m-0 pl-0">
						<b-button
							class="ml-2 w-100"
							variant="secondary"
							:disabled="warehouseId === null"
							@click="downloadImportTemplate()">
							<i class="fas fa-file-csv" /> {{ translate('download_template') }}
						</b-button>
					</b-col>
				</b-row>
				<b-modal
					id="importModal"
					centered
					no-close-on-esc
					no-close-on-backdrop
					hide-header-close>
					<template v-slot:modal-title>
						{{ translate('import_tracking_information') }}
					</template>
					<p>
						{{ translate('download_template_text') }}
						<a
							href="#"
							@click="downloadImportTemplate()">
							<i class="fas fa-download" />
							{{ translate('download_template') }}
						</a>
					</p>
					<template>
						<b-form-file
							v-model="importFile"
							:disabled="isImporting"
							:placeholder="importFile ? importFile.name : translate('select_file')"
							:accept="allowedExt" />
						<p>
							<small>{{ translate('allowed_ext', { ext: allowedExt }) }}</small>
						</p>
					</template>
					<div
						v-if="statistics"
						class="mt-3">
						<!-- eslint-disable vue/no-v-html -->
						<p
							class="text-left"
							v-html="translate('statistics_description')" />
						<ul>
							<li>
								<b>{{ translate('total') }}:</b>
								{{ statistics.total }}
							</li>
							<li
								:class="{
									'text-light-green': statistics.success > 0,
									'text-muted': statistics.success === 0,
								}">
								<b>{{ translate('success') }}:</b>
								{{ statistics.success }}
							</li>
							<li
								:class="{
									'text-danger': statistics.error > 0,
									'text-muted': statistics.error === 0,
								}">
								<b>{{ translate('error') }}:</b>
								{{ statistics.error }}
							</li>
							<li
								:class="{
									'text-success': statistics.already_has_shipment > 0,
									'text-muted': statistics.already_has_shipment === 0,
								}">
								<b>{{ translate('already_has_shipment') }}:</b>
								{{ statistics.already_has_shipment }}
							</li>
						</ul>
					</div>
					<template v-slot:modal-footer>
						<b-button
							:disabled="isImporting"
							variant="secondary"
							@click="$bvModal.hide('importModal'); importFile = null;">
							{{ translate('close') }}
						</b-button>
						<b-button
							:disabled="isImporting || importFile === null"
							variant="primary"
							@click="importOrders()">
							{{ translate('import') }}
						</b-button>
					</template>
				</b-modal>
			</b-col>
		</b-row>
		<div
			v-if="showFilterSection"
			class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
			</div>
		</div>
		<hr v-if="showFilterSection">
		<data-filter
			v-if="showFilterSection"
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div
						class="col-12 col-md-4">
						<div
							class="form-group">
							<label
								for="appFormReceived"
								class="label">{{ translate('app_form_received') }}</label>
							<select
								id="appFormReceived"
								v-model="filters.app_form_received"
								class="form-control">
								<option
									:value="undefined">
									{{ translate('all') }}
								</option>
								<option value="1">
									{{ translate('yes') }}
								</option>
								<option value="0">
									{{ translate('no_word') }}
								</option>
							</select>
						</div>
					</div>
					<div
						class="col-12 col-md-4">
						<div
							class="form-group">
							<label
								for="user_type"
								class="label">{{ translate('user_type') }}</label>
							<select
								id="user_type"
								v-model="filters.user_type"
								class="form-control">
								<option
									:value="undefined">
									{{ translate('all') }}
								</option>
								<option value="member">
									{{ translate('member') }}
								</option>
								<option value="non_member">
									{{ translate('non_member') }}
								</option>
							</select>
						</div>
					</div>
					<div
						class="col-12 col-md-4">
						<div
							class="form-group">
							<label
								for="orderId"
								class="label">{{ translate('order_id') }}</label>
							<input
								id="orderId"
								v-model="filters.orderId"
								:placeholder="translate('type_order')"
								type="text"
								name="orderId"
								class="form-control">
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<b-row>
			<b-col class="col-12">
				<b-tabs v-model="tabIndex">
					<b-tab
						v-for="(tabInfo, index) in tabs"
						:key="index">
						<template #title>
							<router-link
								:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
								:to="{ name: tabInfo.name, query: { ...getQuery(), warehouse_id: warehouseId } }"
								class="list-group-item btn text-left">
								{{ translate(tabInfo.translate_key) }}
							</router-link>
						</template>
						<div
							v-if="tabIndex === index"
							class="p-3">
							<router-view />
						</div>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { Grids, WarehouseOrders } from '@/translations';
import WarehouseOrdersUtil from '@/util/WarehouseOrders';
import WindowSizes from '@/mixins/WindowSizes';
import DataFilter from '@/components/DataFilter';
import FiltersParams from '@/mixins/FiltersParams';
import { QUERY_VARS as noFilterable } from '@/settings/RequestReply';

export default {
	name: 'WarehouseOrders',
	components: {
		DataFilter,
	},
	messages: [Grids, WarehouseOrders],
	mixins: [FiltersParams, WindowSizes],
	data() {
		return {
			tabIndex: 0,
			tabs: [
				{ name: 'PendingWarehouseOrders', translate_key: 'PendingWarehouseOrders_HeaderTitle' },
				{ name: 'ReadyForShipmentWarehouseOrders', translate_key: 'ReadyForShipmentWarehouseOrders_HeaderTitle' },
				{ name: 'WarehouseOrdersExceptions', translate_key: 'WarehouseOrdersExceptions_HeaderTitle' },
			],
			warehouseId: null,
			warehouseOrders: new WarehouseOrdersUtil(),
			download: new WarehouseOrdersUtil(),
			import: new WarehouseOrdersUtil(),
			importFile: null,
			isImporting: false,
			alert: new this.$Alert(),
			allowedExt: '.csv',
			noFilterable: [...noFilterable, 'warehouse_id'],
		};
	},
	computed: {
		warehouses() {
			try {
				const { data } = this.warehouseOrders.data2.response.data;
				return data.map((item) => ({
					value: item.id,
					text: item.attributes.name,
				}));
			} catch (error) {
				return [];
			}
		},
		statistics() {
			try {
				const { statistics } = this.import.data.response.data.response;
				return statistics;
			} catch (error) {
				return null;
			}
		},
		showFilterSection() {
			// Taiwan warehouse only
			return Number(this.warehouseId) === 11 && this.$route.name !== 'WarehouseOrdersExceptions';
		},
	},
	watch: {
		warehouseId() {
			this.$router.push({ query: { ...this.getQuery(), warehouse_id: this.warehouseId } }).catch(() => {});
		},
	},
	mounted() {
		this.getWarehouses();
		if (this.$route.query.warehouse_id) {
			this.warehouseId = this.$route.query.warehouse_id;
		}
	},
	methods: {
		getWarehouses() {
			this.warehouseOrders.getWarehouses();
		},
		downloadImportTemplate() {
			this.download.downloadImportTemplate(this.warehouseId).then(() => {
				const { response } = this.download.data;
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				const filename = `${this.translate('warehouse_orders_import_template')}.csv`;
				link.setAttribute('download', filename);
				this.$el.appendChild(link);
				link.click();
			});
		},
		importOrders() {
			if (!this.importFile) {
				return;
			}
			const formData = new FormData();
			formData.append('file', this.importFile);
			formData.append('warehouse_id', this.warehouseId);
			this.isImporting = true;

			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};

			this.$bvModal.hide('importModal');
			this.alert.loading(this.translate('importing'), this.translate('importing_text'), options);
			this.import.importOrders(formData)
				.then(() => {
					this.alert.close(); // Close the loading alert
					this.$bvModal.show('importModal'); // Show the import modal again
				})
				.catch(() => {
					this.alert.close(); // Close the loading alert
					this.alert.toast('error', this.translate('default_error_message')); // Show the error alert
				})
				.finally(() => {
					this.isImporting = false;
					this.importFile = null; // Clear the import file
				});
		},
		getQuery() {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (Number(this.warehouseId) !== 11) {
				delete options.app_form_received;
				delete options.user_type;
				delete options.orderId;
			}
			return options;
		},
		getDataFiltered() {
			const { name } = this.$route;

			this.$router.push({ name, query: { ...this.getQuery() } }).catch(() => {});
		},
	},
};
</script>
